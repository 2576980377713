<template lang="html">
    <div class="container-fluid p-5">
        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
        <div class="col-md-12">

        </div>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'location',
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                fullPage: true,
                message: "",
                distributors: [],
                contractRequest: {

                },
                validationData: {

                }
            }
        },
        computed: {},
        methods: {
            ...mapActions({
                ItemListAction: 'ItemListAction',
            }),
            async distributorList() {
                this.isLoading = true;
                await this.ItemListAction().then(res => {
                    if (res.status == "success") {
                        this.distributors = res.data
                        this.isLoading = false
                    }
                }).catch(err => this.isLoading = true)
            },
            async onSubmit() {

            },
            validationRule() {

            }
        },
        async mounted() {
            this.distributorList()
        }
    }
</script>

<style scoped lang="scss">
    .action {
        border: none;
        background: none;
        font-size: 24px;
    }
</style>